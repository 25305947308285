import { inject, Injectable } from '@angular/core';
import { StorageUtilsService } from '@qaroni-core/services/app/storage-utils/storage-utils.service';
import { shareReplay, Subject } from 'rxjs';
import { OAuth } from '../types/o-auth';

@Injectable({
  providedIn: 'root',
})
export class OAuthStorageService {
  private oAuth: OAuth;
  protected readonly oAuthSubject = new Subject<OAuth>();

  private lsKey = 'oAuth';

  private storageUtilsService = inject(StorageUtilsService);

  public get(): OAuth {
    if (this.storageUtilsService.validateInLocalStorage(this.lsKey)) {
      return JSON.parse(localStorage.getItem(this.lsKey));
    } else {
      return this.oAuth;
    }
  }

  public get$() {
    return this.oAuthSubject.asObservable().pipe(shareReplay(1));
  }

  public set(oAuth: OAuth): void {
    if (this.storageUtilsService.validateLocalStorage) {
      localStorage.setItem(this.lsKey, JSON.stringify(oAuth));
    } else {
      this.oAuth = oAuth;
    }
    this.oAuthSubject.next(oAuth);
  }

  public reset(): void {
    if (this.storageUtilsService.validateInLocalStorage(this.lsKey)) {
      localStorage.removeItem(this.lsKey);
    } else {
      this.oAuth = undefined;
    }
    this.oAuthSubject.next(null);
  }

  get hasOAuth(): boolean {
    if (this.get() && this.get().access_token && this.get().userId) {
      return true;
    }
    return false;
  }

  get getUserID(): number {
    return this.get().userId;
  }

  get getClientID(): number {
    return this.get().clientId;
  }

  get getMerchantID(): number {
    return this.get().merchantId;
  }
}
