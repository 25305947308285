export const SnackbarConfig = {
  strings: {
    close: 'Cerrar',
  },
  classes: {
    primary: ['qaroni-primary-snackbar'],
    success: ['qaroni-success-snackbar'],
    warning: ['qaroni-warning-snackbar'],
    danger: ['qaroni-danger-snackbar'],
  },
  durations: {
    primary: 6000,
    success: 9000,
    warning: 9000,
    danger: 15000,
  },
};
