import { inject } from '@angular/core';
import { OAuthStorageService } from '@qaroni-app/application/auth/services/o-auth-storage.service';
import { CommonsHttpService } from '@qaroni-core/services/comms/commons-http/commons-http.service';
import { Subject, shareReplay } from 'rxjs';
import {
  QaroniApiLinks,
  QaroniApiResponse,
} from '../qaroni-json-api/qaroni-json-api';

export class QaroniIntermediate {
  protected commons = inject(CommonsHttpService);
  protected storage = inject(OAuthStorageService);

  protected validations = this.commons.validationsHttp;
  protected errors = this.commons.errorsHttp;

  protected readonly linksSubject = new Subject<QaroniApiLinks | null>();
  protected readonly messageSubject = new Subject<string>();

  get hasOAuth(): boolean {
    return this.storage.hasOAuth;
  }

  protected status200 = <T>(response: QaroniApiResponse<T>) =>
    this.validations.verifyStatus200(response);

  protected status201 = <T>(response: QaroniApiResponse<T>) =>
    this.validations.verifyStatus201(response);

  protected status204 = <T>(response: QaroniApiResponse<T>) =>
    this.validations.verifyStatus204(response);

  public getLinks$() {
    return this.linksSubject.asObservable().pipe(shareReplay(1));
  }

  public getMessage$() {
    return this.messageSubject.asObservable().pipe(shareReplay(1));
  }
}
