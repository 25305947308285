import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiQBonoEnv } from '@qaroni-core/config/apis/api-qbono/api-qbono.config';
import {
  OAuthObservableResponse,
  OAuthResponse,
  UserObservableResponse,
  UserResponse,
} from '../types/auth-response';
import {
  ChangePasswordJson,
  ForgotDTO,
  LoginDTO,
  OtpDTO,
  OtpUsernameJson,
  ResetPasswordJson,
  SignUpJson,
  UserDataRegisterDTO,
} from '../types/authentication';
import { IaeObservableResponse, IaeResponse } from '../types/iaes-response';
import { UserRoleEnum } from '../types/user-role.enum';
import { CompleteActivationJson } from '../types/complete-activation';

@Injectable({
  providedIn: 'root',
})
export class OAuthHttpService {
  private http = inject(HttpClient);

  public register$(signUpJSON: SignUpJson): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/registers`;

    return this.http.post<UserResponse>(url, signUpJSON, {
      observe: 'response',
    });
  }

  public login$(login: LoginDTO): OAuthObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/logins`;

    return this.http.post<OAuthResponse>(url, login, {
      observe: 'response',
    });
  }

  public validate$(
    userID: number | string,
    otp: OtpDTO
  ): OAuthObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/validates`;

    return this.http.patch<OAuthResponse>(url, otp, {
      observe: 'response',
    });
  }

  public forgot$(forgot: ForgotDTO): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/forgots`;

    return this.http.post<UserResponse>(url, forgot, {
      observe: 'response',
    });
  }

  public resetPassword$(
    userID: number | string,
    resetPasswordJSON: ResetPasswordJson
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/passwords/resets`;

    return this.http.post<UserResponse>(url, resetPasswordJSON, {
      observe: 'response',
    });
  }

  public otp$(userID: number | string): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/otp`;

    const roleJSON = {
      role: UserRoleEnum.MERCHANT,
    };

    return this.http.post<UserResponse>(url, roleJSON, { observe: 'response' });
  }

  public otpUsername$(
    otpUsernameJSON: OtpUsernameJson
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/otp`;

    return this.http.post<UserResponse>(url, otpUsernameJSON, {
      observe: 'response',
    });
  }

  public changePassword$(
    userID: number | string,
    changePasswordJSON: ChangePasswordJson
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/passwords`;

    return this.http.patch<UserResponse>(url, changePasswordJSON, {
      observe: 'response',
    });
  }

  public getUserRegister$(userID: number | string): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/registers`;

    return this.http.get<UserResponse>(url, { observe: 'response' });
  }

  public updateUserRegister$(
    userID: number | string,
    userDataRegister: UserDataRegisterDTO
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/registers`;

    return this.http.patch<UserResponse>(url, userDataRegister, {
      observe: 'response',
    });
  }

  public getIAEs$(): IaeObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/iaes`;

    return this.http.get<IaeResponse>(url, { observe: 'response' });
  }

  public completeActivation$(
    completeActivationJson: CompleteActivationJson
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/actives`;

    return this.http.post<UserResponse>(url, completeActivationJson, {
      observe: 'response',
    });
  }
}
