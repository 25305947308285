import { QaroniEnumInformation } from '../qaroni-enum-information/qaroni-enum-information';

export enum LanguageEnum {
  default = 'es',
  es = 'es',
  gl = 'gl',
}

export const LanguageEnumArray = [LanguageEnum.es];

export const LanguageEnumInfo: QaroniEnumInformation<LanguageEnum> = {
  [LanguageEnum.es]: {
    name: 'Español',
  },

  [LanguageEnum.gl]: {
    name: 'Galego',
  },
};
