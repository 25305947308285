export const environment = {
  production: false,
};

export const apiQBonoEnvironment = {
  baseUrl: 'https://navalmoral.api.staging.qaroni.net/v1',
};

export const GoogleMapsEnvironment = {
  key: 'AIzaSyBPdC8GOPlFP7ZY1bQpGSTvXuarRO6SNv4',
  faqUrl: '/#/dashboard/support?faqId=32',
};

export const GoogleReCaptchaEnvironment = {
  sitekey: '6Le0MjUqAAAAAB1iEJ7CGXlPQeXLRjwe_zNofB8b',
};
