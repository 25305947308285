import { Injectable } from '@angular/core';
import { QaroniApiResponse } from '@qaroni-core/types/qaroni-json-api/qaroni-json-api';

@Injectable({
  providedIn: 'root',
})
export class ValidationsHttpService {
  private verifyAllResponses<T>(response: QaroniApiResponse<T>): boolean {
    if (response && response.status && response.ok && response.ok === true) {
      return true;
    } else {
      return false;
    }
  }

  private verifyResultResponses<T>(response: QaroniApiResponse<T>): boolean {
    if (
      this.verifyAllResponses(response) &&
      response.body &&
      response.body.result !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  public verifyStatus200<T>(response: QaroniApiResponse<T>): boolean {
    if (this.verifyResultResponses(response) && response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public verifyStatus201<T>(response: QaroniApiResponse<T>): boolean {
    if (this.verifyResultResponses(response) && response.status === 201) {
      return true;
    } else {
      return false;
    }
  }

  public verifyStatus204<T>(response: QaroniApiResponse<T>): boolean {
    if (this.verifyAllResponses(response) && response.status === 204) {
      return true;
    } else {
      return false;
    }
  }
}
